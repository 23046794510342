let isMobile = false;
window.onload = function() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }
}

function mobileTelefone(numero){
    if(isMobile){
        window.location.href = numero;

    }
    
}